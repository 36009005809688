import request from '@/utils/request'
import {
  envInfo
} from '@/constants/envInfo'

export function getList(params) {
  return request({
    url: envInfo.bgApp.dorisPath + '/tenant/logger/findLogger/list',
    method: 'get',
    params
  })
}

/**
 * 查询应用列表
 * @param params
 */
export function findAppList(params) {
  return request({
    url: envInfo.bgApp.lookupPath + `/lookup/item/find/list?classifyCode=${params.classifyCode}`,
    method: 'get',
  })
}

/**
 * 查询日志列表
 * @param params
 * @returns {*}
 */
export function getLoginList(params) {
  return request({
    url: envInfo.bgApp.dorisPath + '/tenant/logger/findTenantLogger/list',
    method: 'get',
    params
  })
}

export function getLogDetail(params) {
  return request({
    url: envInfo.bgApp.dorisPath + '/tenant/logger/getLogDetail',
    method: 'get',
    params,
  });
}

export function getModelList() {
  return request({
    url: envInfo.bgApp.dorisPath + '/tenant/logger/getModelList',
    method: 'get',
  });
}